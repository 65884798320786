import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { Router } from '@angular/router'
import { LocalService } from './local.service'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private localService: LocalService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let userInfo = this.localService.getJsonValue('userInfo')
    const currentUser = userInfo ? JSON.parse(userInfo) : null
    if (currentUser && currentUser.data.SessionId) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
        },
      })
    }

    return next.handle(request).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            // console.log(err.error.message !== 'Invalid session.')
            let msg = err.error
            if (
              (msg.message !== 'Invalid session. Please specify session.' &&
                msg.message !== 'Invalid session.') ||
              err.url.includes('GetUserDetailsById')
            ) {
              return
            } else {
              this.localService.setJsonValue('sessionId', '')
              this.router.navigate(['auth/login'])
            }
            // console.log('AuthInterceptor')
          }
        },
      ),
    )
  }
}
