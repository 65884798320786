import { jwtAuthService } from './../jwt/index'
import { MenuService } from './../menu/index'
import { Injectable, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import store from 'store'
import { select, Store } from '@ngrx/store'
import * as UserActions from 'src/app/store/user/actions'
import * as Reducers from 'src/app/store/reducers'
import IdleTimer from 'src/app/services/IdleTimer'
import { LocalService } from '../local.service'
import { settingsService } from '../settings'
import { ConfigService } from '../config.service'
@Injectable()
export class Permission implements OnInit {
  public userProfilePicture: any

  public MenuAccess = {}
  public allAccess = {}
  menuData: any[]
  session: any
  settings = {
    USERNAME_LABEL: '',
    PASSWORD_LABEL: '',
    PASSWORD_MIN_CHAR: '',
    PASSWORD_MAX_CHAR: '',
    PASSWORD_AL_NUMBER: '',
    PASSWORD_AL_CAP_LETTER: '',
    PASSWORD_AL_SML_LETTER: '',
    PASSWORD_AL_SPL_CHAR: '',
    SESSION_TIMEOUT: '',
    MAX_ATTEMP: '',
    USER_LOCK_TIMEOUT: '',
    DAILY_LIMIT_PASSWORD: '',
    MULTI_SESSION: '',
    ALLOW_SELF_WHITELIST: '',
  }
  sesssionTime: any
  timer: any
  constructor(
    private router: Router,
    private config: ConfigService,
    private menuService: MenuService,
    private Store: Store<any>,
    private jwtAuthService: jwtAuthService,
    private localService: LocalService,
    private settingService: settingsService,
  ) {
    this.getMenuAccess()
    this.loginSettings()
  }

  ngOnInit(): void {
    this.loginSettings()
    setTimeout(() => {
      if (this.settings) {
        this.idleTimeOut(this.settings['SESSION_TIMEOUT'])
      }
    }, 1000)
  }

  getMenuAccess() {
    if (this.localService.getJsonValue('userInfo'))
      this.session = JSON.parse(this.localService.getJsonValue('userInfo'))
    this.menuService.getMenuData().subscribe(menuData => (this.menuData = menuData))
    this.MenuAccess = {}
    this.allAccess = {}
    return this.menuData.reduce((menuAccess, access) => {
      if (this.session && this.session.data.programsPermissions) {
        menuAccess[access] = this.session.data.programsPermissions[0].modules
          .filter(module => {
            return module.moduleName == access.key && module.status == 'A'
          })
          .map(mainModule => {
            if (mainModule.status == 'A') {
              this.allAccess[mainModule.moduleName] = mainModule.rolePermissions
                ? mainModule.rolePermissions.split('~')
                : []

              mainModule.subModules.map(subModule => {
                if (subModule.status == 'A') {
                  this.allAccess[subModule.moduleName] = subModule.rolePermissions
                    ? subModule.rolePermissions.split('~')
                    : []

                  if (subModule.subModules) {
                    subModule.subModules.map(childModule => {
                      if (childModule.status == 'A') {
                        this.allAccess[childModule.moduleName] = childModule.rolePermissions
                          ? childModule.rolePermissions.split('~')
                          : []
                      }
                    })
                  }
                }
              })
            }
          })

        // menuAccess[access] = this.session.SubModulePermission.filter(submodule => {
        //   return submodule.ModuleName == access.key
        // }).map(main_module => {
        //   this.allAccess[main_module.ModuleName] = main_module.ModulePermission
        //     ? main_module.ModulePermission.split('~')
        //     : main_module.ModulePermission
        //   if (main_module.SubModules) {
        //     main_module.SubModules.map(sub_module => {
        //       this.allAccess[sub_module.ModuleName] = sub_module.ModulePermission
        //         ? sub_module.ModulePermission.split('~')
        //         : sub_module.ModulePermission
        //       if (sub_module.SubModules) {
        //         sub_module.SubModules.map(child_module => {
        //           this.allAccess[child_module.ModuleName] = child_module.ModulePermission
        //             ? child_module.ModulePermission.split('~')
        //             : child_module.ModulePermission
        //         })
        //       }
        //     })
        //   }
        //   return main_module
        // })
        return menuAccess
      }
    }, this.MenuAccess)
  }

  hasAccessTo(module, access) {
    this.getMenuAccess()
    return (
      typeof this.allAccess[module] != 'undefined' && this.allAccess[module].indexOf(access) >= 0
    )
  }
  hasMenuAccessTo(menu) {
    this.getMenuAccess()
    return typeof this.MenuAccess[menu] != 'undefined' && this.MenuAccess[menu].length > 1
  }
  loginSettings() {
    if (this.config.getConfig()) {
      this.settingService.getLoginType().subscribe(async state => {
        let data = state.data
        if (data) {
          let fil = data.filter(d => d['pas_code'] == 'LOGIN')
          this.localService.setJsonValue('login_type', fil[0].pas_value)
        }
      })
      this.settingService.getAuthSettings().subscribe(async (d: any) => {
        d.data.map(data => {
          if (
            data.lS_LOGIN_TYPE == this.localService.getJsonValue('login_type') ||
            data.lS_CODE == 'ALLOW_SELF_WHITELIST'
          ) {
            this.settings[data.lS_CODE] = data.lS_VALUE
          }
        })
        this.localService.setJsonValue('settings', JSON.stringify(this.settings))
      })
    }
    return this.settings
      ? this.settings
      : this.localService.getJsonValue('settings')
      ? JSON.parse(this.localService.getJsonValue('settings'))
      : {}
  }
  idleTimeOut(time) {
    if (this.localService.getJsonValue('sessionId')) {
      this.timer = new IdleTimer({
        timeout: time, //expired after 5 mins
        onTimeout: () => {
          this.jwtAuthService.logout()
          this.localService.setJsonValue('sessionId', '')
          this.router.navigate(['auth/login'])
        },
      })
    }
  }
}
