import { CustomHttpService } from './../../services/custom-http.service'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import store from 'store'
import { environment } from './../../../environments/environment'
import { Router } from '@angular/router'

@Injectable()
export class playerService {
  constructor(private http: CustomHttpService, private router: Router) {}

  getDashboardStatistics(): Observable<any> {
    return this.http.post(`dashboard/GetDashboardStatistics`, {})
  }

  getPlayerList(params): Observable<any> {
    let filter = ''
    let val = ''
    if (params.filter && params.filter.length) {
      if (params.filter == 'created') filter = 'datecreated'
      else filter = params.filter

      if (filter == 'status') {
        if ('active'.includes(params.value.toLowerCase())) val = 'a'
        else if ('closed'.includes(params.value.toLowerCase())) val = 'c'
      }
    }
    return this.http.post(`dashboard/Patron`, {
      filter: params.filter ? (params.filter.length ? filter : '') : 'customerid',
      value: val || params.value || params.data || '',
      startdate: params.startdate ? params.startdate : '',
      enddate: params.enddate ? params.enddate : '',
      page: params.pageIndex || 1,
      limit: params.pageSize || 10,
      sortOrder: params.sortOrder,
      sortColumn: params.sortColumn,
    })
  }

  getPlayerDetails(params): Observable<any> {
    // return this.http.get(`dashboard/Patron/${params.data}`)
    return this.http.post('dashboard/Patron', {
      filter: '',
      value: '',
      startdate: '',
      enddate: '',
      page: params.pageIndex || 1,
      limit: params.pageSize || 10,
    })
  }

  getPlayerFeeInfo(params): Observable<any> {
    return this.http.post(`dashboard/Transaction`, {
      filter: 'CWA_PATRON_ID',
      value: params.data || '',
      startdate: '',
      enddate: '',
      page: '1',
      limit: '10',
    })
  }

  // getPlayerMarkerInfo(params): Observable<any> {
  //   return this.http.get(`Dashboard/MarkerLimit/${params.data}`)
  // }

  makePayment(params): Observable<any> {
    const data = {
      CustomerId: params.customerId,
      Amount: (Math.round(params.amount * 100) / 100).toFixed(2),
      TransactionType: 'LOAD_CAGE',
      TransactionTenderType: params.type,
      TransactionTimestamp: new Date()
        .toISOString()
        .replace('T', ' ')
        .replace('Z', '')
        .split('.')
        .shift(),
      TransactionDescription: params.description,
      CashierName: params.fullName,
    }
    return this.http.post(`Dashboard/LoadTransaction`, data)
  }

  updateStatus(params): Observable<any> {
    return this.http.post(`Dashboard/UpdateCustomerStatus`, params)
  }

  makeAdjustment(params): Observable<any> {
    const data = {
      amount: parseFloat(params.data.amount),
      method: params.data.method,
    }
    return this.http.post(
      `Dashboard/cwaWithdraw/${params.data.playerCardNumber}`,
      JSON.stringify(data),
    )
  }

  getPlayerAct(params): Observable<any> {
    return this.http.post(`dashboard/TransactionByPatron`, {
      customerid: params.data.value || '',
      startdate: params.data.startdate,
      enddate: params.data.enddate,
      page: params.pagination.pageIndex.toString(),
      limit: params.data.limit.toString() || params.pagination.pageSize.toString(),
    })
  }

  getLimitAct(params): Observable<any> {
    return this.http.get(`Dashboard/GetAdvanceLineChangeByPlayerNumber/${params.data}`)
  }

  getFeeAct(params): Observable<any> {
    return this.http.post(`Dashboard/PatronFee`, params.data)
  }

  getPaymentAct(params): Observable<any> {
    if (params.data.api == 'All') params.data.api = 'PaymentActivity'
    else params.data.api = 'Payment/' + params.data.api
    return this.http.get(`Dashboard/${params.data.api}/${params.data.card}/10000/1`)
  }

  getStatementList(params): Observable<any> {
    return this.http.get(`Dashboard/Statement/${params.data}`)
  }

  playerAgreement(params): Observable<any> {
    const data = {
      sign_dt: params.data.date,
    }
    return this.http.post(
      `Dashboard/SignAgreement/${params.data.playerCardNumber}`,
      JSON.stringify(data),
    )
  }
  generateAgreement(params): Observable<any> {
    return this.http.post(`Dashboard/GenerateAgreementV2/${params.data.playerCardNumber}`, {})
  }
  generateStatement(params): Observable<any> {
    return this.http.getv2(`Dashboard/Statement/Download/${params.data}`, {
      responseType: 'blob',
    })
  }
  previewAgreement(patronId): Observable<any> {
    // return this.http.postBlob(`Dashboard/PreviewAgreement/${params.data}`, JSON.stringify(params))
    return this.http.post(`Dashboard/PreviewAgreementV2/${patronId.data}`, {})
  }

  getCreditFactor(playerCardNumber): Observable<any> {
    return this.http.get(`Dashboard/CreditFactor/${playerCardNumber}`)
  }

  cashOut(params): Observable<any> {
    const data = {
      amount: parseFloat(params.data.amount),
      pin: params.data.pin,
    }
    return this.http.post(`Dashboard/cashout/${params.data.playerCardNumber}`, JSON.stringify(data))
  }
  internalAdjustment(params): Observable<any> {
    const data = {
      amount: params.data.amount,
      description: params.data.description,
      adjustmenttype: params.data.method,
    }
    return this.http.post(
      `Dashboard/adjustment/${params.data.playerCardNumber}`,
      JSON.stringify(data),
    )
  }
  getPatronStatus(params): Observable<any> {
    const param = params.from && params.to ? '/' + params.from + '/' + params.to : ''
    return this.http.get(`Dashboard/PatronStatusCount${param}`)
  }
  getTotalFee(params): Observable<any> {
    const param = params.from && params.to ? '/' + params.from + '/' + params.to : ''
    return this.http.get(`Dashboard/TransactionCountTotalFee${param}`)
  }
  getPaymentInfo(params): Observable<any> {
    const param = params.from && params.to ? '/' + params.from + '/' + params.to : ''
    return this.http.get(`Dashboard/PaymentInformation${param}`)
  }
  getPlayerNotes(data, type?): Observable<any> {
    if (type == 1) {
      data.options.limit = data.limit
      data.options.page = data.page
      return this.http.post(`Dashboard/GetNotifications`, data.options)
    } else {
      return this.http.post(`Dashboard/Note`, data)
    }
  }
  addPlayerNotes(params): Observable<any> {
    return this.http.post(`Dashboard/CreateNote`, params)
  }
  editPlayerNotes(params): Observable<any> {
    return this.http.patch(`Identity/EditPatronNotes`, params)
  }
  deletePlayerNotes(Id): Observable<any> {
    return this.http.post(`Dashboard/DeleteNote`, { Id })
  }
  addMarkerLine(params): Observable<any> {
    return this.http.post(`Dashboard/InitiateAdvanceLineChange`, params)
  }
  getAdvanceLine(id): Observable<any> {
    return this.http.get(`Dashboard/GetAdvanceLineChange/${id}`)
  }
  updateAdLineStatus(params): Observable<any> {
    return this.http.post(`Dashboard/UpdateLineChangeRequest`, params)
  }
  getPatronNotif(params): Observable<any> {
    return this.http.get(`Dashboard/GetNotificationHistoryByLogId/${params.data}`)
  }
}
