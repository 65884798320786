import { ConfigService } from './../config.service'
import { CustomHttpService } from './../../services/custom-http.service'
import { Injectable } from '@angular/core'
import { EMPTY, Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import store from 'store'
import { environment } from './../../../environments/environment'
import { Router } from '@angular/router'

@Injectable()
export class jwtAuthService {
  constructor(
    private http: CustomHttpService,
    private router: Router,
    public config: ConfigService,
  ) {}

  login(EmailAddress: string, Password: string, IpAddress: string): Observable<any> {
    return this.http.post(`auth/login`, {
      Username: EmailAddress,
      Password: Password,
      IPAddress: IpAddress,
    })
  }

  register(email: string, password: string, name: string): Observable<any> {
    return this.http.post('/api/auth/register', { email, password, name })
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post('auth/forgotPassword', { username: email })
  }

  setPassword(params): Observable<any> {
    if (params.id) {
      // from email request
      return this.http.put('auth/setPasswordByLinkedId', {
        linkedId: params.id,
        password: params.password,
      })
    } else {
      // from force change pw
      return this.http.put('user/ChangePassword', {
        oldPassword: params.oldPassword,
        newPassword: params.password,
      })
    }
  }

  currentAccount(params): Observable<any> {
    if (!params) {
      return EMPTY
    }

    params = JSON.parse(params)
    return this.http.get(`user/GetUserDetailsById/${params.data.userId}`)
  }

  logout(): Observable<any> {
    return this.http.post(`user/logout`, {})
  }
  getLoginSettings(): Observable<any> {
    return this.http.get('settings/LoginSettings')
  }
  getLoginType(): Observable<any> {
    return this.http.get('general/AdminSettings')
  }
  setIPAddress(data): Observable<any> {
    return this.http.post('Identity/SendIpAddressVerification', data)
  }
  validateIPAddress(data): Observable<any> {
    return this.http.post('Identity/ConfirmVerification', data)
  }
}
