import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from 'src/app/shared.module'
import { LayoutsModule } from 'src/app/layouts/layouts.module'
import { AppPreloader } from 'src/app/app-routing-loader'
import { AuthGuard } from 'src/app/components/cleanui/system/Guard/auth.guard'

// layouts & notfound
import { LayoutAuthComponent } from 'src/app/layouts/Auth/auth.component'
import { LayoutMainComponent } from 'src/app/layouts/Main/main.component'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutMainComponent,
    children: [
      {
        path: 'dashboard',
        // canActivate: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'customer-management',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/player-management/player-management.module').then(
            m => m.PlayerManagementModule,
          ),
      },
      {
        path: 'users',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/employees/employees.module').then(m => m.EmployeesModule),
      },
      {
        path: 'reports',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: 'edit-profile',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/profile/edit-profile/edit-profile.module').then(
            m => m.EditProfileModule,
          ),
      },
      {
        path: 'change-password',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/change-password/change-password.module').then(
            m => m.ChangePasswordModule,
          ),
      },
      {
        path: 'properties-management',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/properties-management/properties-management.module').then(
            m => m.PropertiesManagementModule,
          ),
      },
      {
        path: 'notifications',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/notifications/notifications.module').then(
            m => m.NotificationsModule,
          ),
      },
      {
        path: 'settings',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/settings/settings.module').then(m => m.SettingsModule),
      },
      {
        path: 'settlement',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/settlement/settlement.module').then(m => m.SettlementModule),
      },
    ],
  },
  {
    path: 'auth',
    component: LayoutAuthComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/pages/auth/auth.module').then(m => m.AuthModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/auth/404',
  },
]

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: AppPreloader,
      relativeLinkResolution: 'legacy',
    }),
    LayoutsModule,
  ],
  providers: [AppPreloader],
  exports: [RouterModule],
})
export class AppRoutingModule {}
