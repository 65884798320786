import { select, Store } from '@ngrx/store'
import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  Output,
  Input,
  EventEmitter,
} from '@angular/core'
import * as PlayerActions from 'src/app/store/player/actions'
import * as Reducers from 'src/app/store/reducers'
import * as SettingsActions from 'src/app/store/settings/actions'
import Fuse from 'fuse.js'
import { playerService } from 'src/app/services/player'

interface DataItem {
  playercardnumber: string
  lastname: string
  firstname: string
  modified: Date
  status: string
}

@Component({
  selector: 'cui-topbar-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class TopbarSearchComponent implements OnInit {
  @ViewChild('liveSearchInput') liveSearchInput: ElementRef

  showSearch: boolean = false
  searchText: string = ''
  playerFilter: any
  playerList: DataItem[] = []
  filteredPlayerList: DataItem[] = []
  pageOptions = []
  pageSize = 1000
  pageTotal = 0
  pageIndex = 1
  constructor(private store: Store<any>, private playerService: playerService) {
    document.addEventListener('keydown', this.handleKeyDown.bind(this), false)
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.showSearch = state.isLiveSearchVisible
    })
  }

  ngOnInit(): void {
    // console.log('Im in here')
    this.getPlayerList({ data: this.searchText })
  }

  setVisible() {
    this.showSearch = true
    this.getPlayerList({ data: this.searchText })
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        isLiveSearchVisible: true,
      }),
    )
    setTimeout(() => {
      this.liveSearchInput.nativeElement.focus()
    }, 100)
  }

  setHidden() {
    this.showSearch = false
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        isLiveSearchVisible: false,
      }),
    )
  }

  handleKeyDown(event: any) {
    if (this.showSearch) {
      const key = event.keyCode.toString()
      if (key === '27') {
        this.setHidden()
      }
    }
  }
  options: any = ''
  getPlayerList(params) {
    // this.store.dispatch(new PlayerActions.PlayerList())
    // this.store.pipe(select(Reducers.getPlayerList)).subscribe(state => {
    //   const dataList = state.PlayerList
    //   setTimeout(() => {
    //     if (dataList) {
    //       this.playerList = dataList.data
    //     }
    //   }, 250)
    // })
    params = {
      ...params,
      filter: 'cwapatronid|firstname|lastname',
    }
    return this.playerService.getPlayerList(params).subscribe(res => {
      this.pageTotal = res.total
      res.data = res.data.filter(x => x.Status !== 'New Patron' && x.Status !== 'For Migration')
      res.data = res.data.map(v => {
        // v.created = moment(v.created).format('YYYY-MM-DD hh:mm A')
        // v.modified = moment(v.modified).format('YYYY-MM-DD hh:mm A')
        v.Status = v.Status == 'P' ? 'Pending' : v.Status == 'A' ? 'Active' : 'Deleted'
        return v
      })
      res.data = res.data.sort((a, b) => {
        return new Date(`${a.created}`).getTime() > new Date(`${b.created}`).getTime() ? -1 : 1
      })
      this.playerList = res.data
      this.filteredPlayerList = res.data
    })
  }

  filterPlayer() {
    this.playerList = []
    if (this.playerList && this.playerList.length == 0) {
      this.getPlayerList({ data: this.searchText })
    }
    if (this.playerList) {
      const options = {
        tokenize: true,
        fieldNormWeight: 0,
        threshold: 0.0,
        location: 0,
        distance: 300,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        ignoreLocation: true,
        keys: ['playerCardNumber', 'lastName', 'firstName', 'modified', 'status'],
      }
      const lists = this.playerList
      const fuse = new Fuse(lists, options)

      this.filteredPlayerList = []
      this.filteredPlayerList = this.searchText
        ? fuse.search(this.searchText).map(item => {
            return item.item
          })
        : lists
    }
  }
}
