import { CustomHttpService } from './../../services/custom-http.service'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import store from 'store'
import { environment } from './../../../environments/environment'
import { Router } from '@angular/router'

@Injectable()
export class settingsService {
  constructor(private http: CustomHttpService, private router: Router) {}

  getTimezoneList(): Observable<any> {
    return this.http.get(`general/TimezoneAll`)
  }
  getCurrentTimezone(): Observable<any> {
    return this.http.get(`general/Timezone`)
  }
  updateTimezone(data): Observable<any> {
    return this.http.put(`general/Timezone`, {
      utc: data,
    })
  }
  updateIP(): Observable<any> {
    return this.http.put(`Identity/EnableDisableIPWhiteListing`, {})
  }
  getIPAddress(): Observable<any> {
    return this.http.get(`general/IPAddress`)
  }
  getCurrentIP(): Observable<any> {
    return this.http.get(`Identity/IPAddressSettings`)
  }
  updateSelfIP(): Observable<any> {
    return this.http.put(`Identity/EnableDisableSelfIPWhitelisting`, {})
  }
  checkValidIp(params): Observable<any> {
    return this.http.post(`Identity/CheckIfIPIsAllowedToAccess`, { IP_ADDRESS: params })
  }
  addIPAddress(data): Observable<any> {
    return this.http.post(`general/IPAddress`, {
      IP_ADDRESS: data.ip,
      DESCRIPTION: data.desc,
      PROCESSED_BY: data.processed_by,
      STATUS: 'A',
    })
  }
  updateIPAddress(data): Observable<any> {
    return this.http.put(`general/IPAddress`, {
      IP_ADDRESS: data.ip,
      DESCRIPTION: data.desc,
      ID: data.id,
      STATUS: data.stat,
    })
  }
  updateIPStatus(ip): Observable<any> {
    return this.http.put(`general/IPAddress`, ip)
  }
  deleteIPAddress(id): Observable<any> {
    return this.http.delete(`general/IPAddress/${id}`)
  }
  getNotificationSettings(): Observable<any> {
    return this.http.get(`settings/Notification`)
  }
  updateNotificationSettings(params): Observable<any> {
    return this.http.put(`settings/Notification`, params)
  }
  getAuthSettings(): Observable<any> {
    return this.http.get(`settings/LoginSettings`)
  }
  getLoginType(): Observable<any> {
    return this.http.get('general/AdminSettings')
  }
  updateAuthSettings(params): Observable<any> {
    let method = params.LoginType == 'EMAIL' ? 'LoginSettingsEmail' : 'LoginSettingsNonEmail'
    return this.http.put(`settings/${method}`, params)
  }
  updateLoginType(params): Observable<any> {
    return this.http.put(`general/AdminSettings`, {
      pas_id: '3',
      pas_code: 'LOGIN',
      pas_value: params,
    })
  }
  savePointLits(params): Observable<any> {
    return this.http.post(`Identity/AddRemovePerimeter`, params)
  }
  // getPointLits(): Observable<any> {
  //   return this.http.get(`Identity/Perimeter`)
  // }
  deletePerimeter(): Observable<any> {
    return this.http.delete(`Identity/ResetPerimeter`)
  }
  updateGeo(): Observable<any> {
    return this.http.patch(`Identity/EnableDisableGeoFence`, {})
  }
  getGeoStat(): Observable<any> {
    return this.http.get(`settings/Geofence`)
  }
  getCutOff(): Observable<any> {
    return this.http.get(`general/AdminSettings`)
  }
  updatetCutOff(params): Observable<any> {
    return this.http.patch(`Identity/PropertyCutoffSetting`, params)
  }
  getUnderwritingVersions(): Observable<any> {
    return this.http.get(`settings/Underwriting`)
  }
  getCurrentCheckPayment(): Observable<any> {
    return this.http.get(`Identity/GetDefaultCheckSetting`)
  }
  updateCheckPayment(data): Observable<any> {
    return this.http.post(`Identity/CheckAutoPostSetting`, {
      value: data.toString(),
    })
  }
  getUnderwritingFields(data): Observable<any> {
    return this.http.get(`Dashboard/GetParameterByVersionName/` + data)
  }
  underwriteSimulator(params): Observable<any> {
    return this.http.post(
      `Dashboard/UnderwriteSimulator/${params.PatronID}/${params.VersionName}`,
      params.Request,
    )
  }
  getCssStyles(): Observable<any> {
    return this.http.post(`Dashboard/GetCssStyles`, {})
  }
  getApprovalSettings(): Observable<any> {
    return this.http.get(`Dashboard/GetApprovalSetting`)
  }
  updateApprovalSettings(data): Observable<any> {
    return this.http.post(`Dashboard/UpdateApproverCount`, data)
  }
}
