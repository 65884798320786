import { Injectable } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Actions, Effect, ofType, OnInitEffects } from '@ngrx/effects'
import { Action, select, Store } from '@ngrx/store'
import { from, Observable, of } from 'rxjs'
import { map, switchMap, catchError, withLatestFrom, concatMap, switchMapTo } from 'rxjs/operators'
import store from 'store'
import { NzNotificationService } from 'ng-zorro-antd'

import * as Reducers from 'src/app/store/reducers'
import * as userAct from './../user/actions'
import * as act from './actions'

import { userMenuService } from 'src/app/services/userMenu'
import { LocalService } from 'src/app/services/local.service'

@Injectable()
export class UserMenuEffects {
  constructor(
    private actions: Actions,
    private userMenuService: userMenuService,
    private router: Router,
    private route: ActivatedRoute,
    private rxStore: Store<any>,
    private notification: NzNotificationService,
    private localService: LocalService,
  ) {}

  @Effect()
  updateProfile: Observable<any> = this.actions.pipe(
    ofType(act.UPDATE_PROFILE),
    map((action: act.UpdateProfile) => action.data),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([userData, settings]) => {
      return this.userMenuService.updateProfile(userData).pipe(
        map(res => {
          let userInfo = JSON.parse(this.localService.getJsonValue('userInfo'))
          userInfo = {
            ...userInfo,
            data: userData,
          }
          this.localService.setJsonValue('userInfo', JSON.stringify(userInfo))
          this.notification.success('Success', `Profile Updated!`)
          return new act.UpdateProfileSuccess(res)
        }),
        catchError(error => {
          console.log(error)
          this.notification.warning('Error', `${error}`)
          return from([{ type: act.UPDATE_PROFILE_UNSUCCESSFUL }])
        }),
      )
    }),
  )

  @Effect()
  updatePassword: Observable<any> = this.actions.pipe(
    ofType(act.UPDATE_PASSWORD),
    map((action: act.UpdatePassword) => action.data),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([userData, settings]) => {
      return this.userMenuService.updatePassword(userData).pipe(
        map(res => {
          console.log(res)
          if (!res.isSuccess) {
            if (res.message === 'The password is not correct.') {
              res.message = 'Incorrect old password.'
            }
            this.notification.success('Error', res.message)
            return new act.UpdatePasswordSuccess(res)
          } else {
            this.notification.success('Success', `Password Updated!`)
            return new act.UpdatePasswordSuccess(res)
          }
        }),
        catchError(error => {
          let msg = error.error.message
          if (msg === 'The password is not correct.') {
            msg = 'Incorrect old password.'
          } else if (msg === 'Unable to Change Password, Please Check Parameters.') {
            msg = 'Incorrect Password.'
          }

          this.notification.warning('Error', `${msg}`)
          return from([{ type: act.UPDATE_PASSWORD_UNSUCCESSFUL }])
        }),
      )
    }),
  )
}
