import { CustomHttpService } from './../../services/custom-http.service'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import store from 'store'
import { environment } from './../../../environments/environment'
import { Router } from '@angular/router'
import * as moment from 'moment'

@Injectable()
export class userManagementService {
  constructor(private http: CustomHttpService, private router: Router) {}

  getEventLogList(payload): Observable<any> {
    return this.http.get(`Dashboard/EventLog/${payload}`)
  }
  getUserList(): Observable<any> {
    return this.http.get(`user/GetUserList`)
  }

  getRoleList(): Observable<any> {
    return this.http.get(`role/GetRoleList`)
  }

  getGroupList(): Observable<any> {
    return this.http.get(`group/GetGroupList`)
  }

  addUser(userData): Observable<any> {
    return this.http.post(`user/CreateUserWithPassword`, {
      userRoleId: userData.rolesId,
      username: userData.username.trim(),
      firstName: userData.firstName,
      middleName: '',
      lastName: userData.lastName,
      mobile: userData.mobile,
      email: userData.username.trim(),
      address: '',
      title: '',
      status: userData.status == 'inactive' ? 'I' : 'A',
    })
  }

  updateGroup(groupData): Observable<any> {
    return this.http.post(`group/SaveGroup`, {
      groupsId: groupData.GroupId,
      groupsProgramId: groupData.GroupProgramId,
      groupsCode: groupData.GroupCode,
      groupsName: groupData.GroupName,
      groupsDescription: groupData.GroupDescription,
    })
  }

  deleteGroup(groupData): Observable<any> {
    return this.http.delete(`group/DeleteGroup/${groupData}`)
  }

  updateUser(userData): Observable<any> {
    return this.http.put(`user/UpdateUserDetailsById`, {
      userId: userData.userId,
      firstName: userData.firstName,
      lastName: userData.lastName,
      mobile: userData.mobile
        .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
        .replace(/\s+/g, ''),
      userRoleId: userData.rolesId,
      title: userData.title,
      status: userData.status,
      badgeNumber: userData.badgeNumber,
      dob: userData.dob ? moment(userData.dob).format('YYYY-MM-DD') : '',
    })
  }

  deleteUser(userData): Observable<any> {
    return this.http.delete(`user/DeleteUser/${userData}`)
  }

  getModuleList(): Observable<any> {
    return this.http.get(`module/GetModulesPerProgram`)
  }

  addRole(roleData): Observable<any> {
    return this.http.post(`role/SaveRole`, {
      rolesId: '',
      rolesProgramId: '1',
      rolesName: roleData.RoleName,
      rolesDesc: roleData.RoleDescription,
      rolesGroupId: roleData.GroupId,
      rolesPermission: roleData.ModulePermission,
    })
  }

  updateRole(roleData): Observable<any> {
    return this.http.post(`role/SaveRole`, {
      rolesId: roleData.RoleId,
      rolesProgramId: '1',
      rolesName: roleData.RoleName,
      rolesDesc: roleData.RoleDescription,
      rolesGroupId: roleData.GroupId,
      rolesPermission: roleData.ModulePermission,
    })
  }

  deleteRole(roleData): Observable<any> {
    return this.http.delete(`role/DeleteRoles/${roleData}`)
  }
  addPermission(data): Observable<any> {
    return this.http.put(`Identity/AddRolesModulePermissionBatch`, {
      RoleId: data.RoleId,
      ModuleId_PermissionCode: data.ModulePermission,
    })
  }
  operatorResetPassword(username): Observable<any> {
    return this.http.post(`auth/resetPassword`, {
      username,
    })
  }
}
