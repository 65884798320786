import { Permission } from 'src/app/services/permission/index'
import { MiscFunctions } from './../../../../../misc/index'
import { Component } from '@angular/core'
import { select, Store } from '@ngrx/store'
import * as UserActions from 'src/app/store/user/actions'
import * as Reducers from 'src/app/store/reducers'
import session from 'store'
import { Router } from '@angular/router'
import { NzNotificationService } from 'ng-zorro-antd'
import { LocalService } from 'src/app/services/local.service'

@Component({
  selector: 'cui-topbar-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class TopbarUserMenuComponent {
  name: string = ''
  role: string = ''
  userInfo: ''
  settings = {}
  constructor(
    private store: Store<any>,
    private router: Router,
    private notification: NzNotificationService,
    private misc: MiscFunctions,
    private permission: Permission,
    private localService: LocalService,
  ) {
    this.userInfo = this.localService.getJsonValue('userInfo')
      ? JSON.parse(this.localService.getJsonValue('userInfo'))
      : ''
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.name = state.firstName ? state.firstName : this.userInfo['data']['firstName']
      this.role = state.userType ? state.userType : this.userInfo['data']['roleName']
    })
  }
  ngOnInit(): void {
    this.settings = this.permission.loginSettings()
  }

  logout() {
    this.store.dispatch(new UserActions.Logout())
    // this.localService.clearToken()
  }
}
