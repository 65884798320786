import { Injectable } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Actions, Effect, ofType, OnInitEffects } from '@ngrx/effects'
import { Action, select, Store } from '@ngrx/store'
import { from, Observable, of } from 'rxjs'
import { map, switchMap, catchError, withLatestFrom, concatMap, switchMapTo } from 'rxjs/operators'
import store from 'store'
import { NzNotificationService } from 'ng-zorro-antd'

import * as Reducers from 'src/app/store/reducers'
import * as act from './actions'
import { reportService } from 'src/app/services/report'
import * as moment from 'moment'
import { CurrencyPipe } from '@angular/common'
import { MiscFunctions } from 'src/app/misc'

@Injectable()
export class ReportEffects {
  reportPlayerActivity: [] = []
  reportSettlement: [] = []
  reportLimitActivity: [] = []
  reportFeeActivity: [] = []
  reportPaymentActivity: [] = []
  reportScoringFee: [] = []
  reportProgramFee: [] = []
  reportPSR: [] = []
  reportCWAEnableDisable: [] = []
  reportCwaDetail: [] = []
  reportCwaAdjustment: [] = []
  reportProgManamgementFees: [] = []
  reportUtilization: [] = []
  reportType = ''

  constructor(
    private actions: Actions,
    private reportService: reportService,
    private router: Router,
    private route: ActivatedRoute,
    private rxStore: Store<any>,
    private notification: NzNotificationService,
    private currencyPipe: CurrencyPipe,
    public misc: MiscFunctions,
  ) {}

  @Effect()
  getReportPlayerActivity: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_PLAYER_ACTIVITY),
    map((action: act.ReportPlayerActivity) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportPlayerActivity(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.CWA_PATRON_ID = typeof v.CWA_PATRON_ID !== 'object' ? v.CWA_PATRON_ID : ''
            v.Firstname = typeof v.Firstname !== 'object' ? v.Firstname : ''
            v.Lastname = typeof v.Lastname !== 'object' ? v.Lastname : ''
            v.TRAN_TYPE_DESCRIPTION =
              typeof v.TRAN_TYPE_DESCRIPTION !== 'object' ? v.TRAN_TYPE_DESCRIPTION : ''
            v.TRANSACTION_AMOUNT =
              typeof v.TRANSACTION_AMOUNT !== 'object' ? v.TRANSACTION_AMOUNT : 0
            v.TRANSACTION_CREATE_DATE = moment(v.TRANSACTION_CREATE_DATE).format(
              'YYYY-MM-DD hh:mm:ss A',
            )
            v.TRANSACTION_STATUS_LABEL =
              v.TRANSACTION_STATUS == 'A'
                ? 'Processed'
                : v.TRANSACTION_STATUS == 'P'
                ? 'Pending'
                : 'Declined'
            v.WALLET_BALANCE = this.currencyPipe.transform(
              v.WALLET_BALANCE,
              'USD',
              'symbol',
              '1.2-2',
            )
            return v
          })
          this.reportPlayerActivity = []
          this.reportPlayerActivity['ReportPlayerActivity'] = res
          return new act.ReportPlayerActivitySuccess(this.reportPlayerActivity)
        }),
      )
    }),
  )
  @Effect()
  getReportSettlement: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_SETTLEMENT),
    map((action: act.ReportSettlement) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportSettlement(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.CustomerName = typeof v.CustomerName !== 'object' ? v.CustomerName : ''
            v.TRAN_TYPE_DESCRIPTION_UI =
              typeof v.TRAN_TYPE_DESCRIPTION_UI !== 'object' ? v.TRAN_TYPE_DESCRIPTION_UI : ''
            v.CUSTOMER_EMAIL = typeof v.CUSTOMER_EMAIL !== 'object' ? v.CUSTOMER_EMAIL : ''
            v.TRANSACTION_AMOUNT =
              typeof v.TRANSACTION_AMOUNT !== 'object' ? v.TRANSACTION_AMOUNT : 0
            v.TRANSACTION_TIMESTAMP = moment(v.TRANSACTION_TIMESTAMP).format(
              'YYYY-MM-DD hh:mm:ss A',
            )
            v.TRANSACTION_AMOUNT = this.currencyPipe.transform(
              v.TRANSACTION_AMOUNT,
              'USD',
              'symbol',
              '1.2-2',
            )
            return v
          })
          this.reportSettlement = []
          this.reportSettlement['ReportSettlement'] = res
          return new act.ReportSettlementSuccess(this.reportSettlement)
        }),
      )
    }),
  )
  @Effect()
  getReportUtilization: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_UTILIZATION),
    map((action: act.ReportUtilization) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportUtilization(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.CWA_PATRON_ID = typeof v.CWA_PATRON_ID !== 'object' ? v.CWA_PATRON_ID : ''
            v.WALLET_BALANCE = typeof v.WALLET_BALANCE !== 'object' ? v.WALLET_BALANCE : 0
            v.CUSTOMER_EMAIL = typeof v.CUSTOMER_EMAIL !== 'object' ? v.CUSTOMER_EMAIL : ''
            v.CUSTOMER_PHONE = typeof v.CUSTOMER_PHONE !== 'object' ? v.CUSTOMER_PHONE : ''
            v.FUNDED_DATE = typeof v.FUNDED_DATE !== 'object' ? v.FUNDED_DATE : ''
            v.TRANSFERRED_DATE = typeof v.TRANSFERRED_DATE !== 'object' ? v.TRANSFERRED_DATE : ''
            v.ENROLLMENT_DATE = typeof v.ENROLLMENT_DATE !== 'object' ? v.ENROLLMENT_DATE : ''

            v.FUNDED_DATE = moment(v.FUNDED_DATE).format('YYYY-MM-DD hh:mm:ss A')
            v.TRANSFERRED_DATE = moment(v.TRANSFERRED_DATE).format('YYYY-MM-DD hh:mm:ss A')
            v.ENROLLMENT_DATE = moment(v.ENROLLMENT_DATE).format('YYYY-MM-DD hh:mm:ss A')
            v.WALLET_BALANCE = this.currencyPipe.transform(
              v.WALLET_BALANCE,
              'USD',
              'symbol',
              '1.2-2',
            )
            return v
          })
          this.reportUtilization = []
          this.reportType = payload.payload.type
          this.reportUtilization[this.reportType] = res
          return new act.ReportUtilizationSuccess(this.reportUtilization)
        }),
      )
    }),
  )
  @Effect()
  getReportLimitActivity: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_LIMIT_ACTIVITY),
    map((action: act.ReportLimitActivity) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportLimitActivity(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.accountingdate = moment(v.accountingdate).format('YYYY-MM-DD')
            v.datecreated = moment(v.datecreated).format('YYYY-MM-DD hh:mm:ss A')
            v.datemodified = moment(v.datemodified).format('YYYY-MM-DD hh:mm:ss A')
            v.totalLimitCurrency = this.misc.transformCurrency(v.totalLimit)
            return v
          })
          this.reportLimitActivity = []
          this.reportLimitActivity['ReportLimitActivity'] = res
          return new act.ReportLimitActivitySuccess(this.reportLimitActivity)
        }),
      )
    }),
  )
  @Effect()
  getReportFeeActivity: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_FEE_ACTIVITY),
    map((action: act.ReportFeeActivity) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportFeeActivity(payload).pipe(
        map(res => {
          res.data = res.data
            .filter(x => x.FeeType !== 'Scoring fee assessed to casino')
            .map(v => {
              v.accountingdate = moment(v.accountingdate).format('YYYY-MM-DD')
              v.Date = moment(v.Date).format('YYYY-MM-DD hh:mm:ss A')
              v.BaseAmountCurrency = this.currencyPipe.transform(
                v.BaseAmount,
                'USD',
                'symbol',
                '1.2-2',
              )
              v.FeeAmountCurrency = this.currencyPipe.transform(
                v.FeeAmount,
                'USD',
                'symbol',
                '1.2-2',
              )
              return v
            })
          this.reportFeeActivity = []
          this.reportFeeActivity['ReportFeeActivity'] = res
          return new act.ReportFeeActivitySuccess(this.reportFeeActivity)
        }),
      )
    }),
  )
  @Effect()
  getReportPaymentActivity: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_PAYMENT_ACTIVITY),
    map((action: act.ReportPaymentActivity) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportPaymentActivity(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.accountingdate = moment(v.accountingdate).format('YYYY-MM-DD')
            v.datecreated = moment(v.datecreated).format('YYYY-MM-DD hh:mm:ss A')
            v.datemodified = moment(v.datemodified).format('YYYY-MM-DD hh:mm:ss A')
            v.debitCurrency = this.currencyPipe.transform(v.debit, 'USD', 'symbol', '1.2-2')
            v.creditCurrency = this.currencyPipe.transform(v.credit, 'USD', 'symbol', '1.2-2')
            return v
          })
          this.reportPaymentActivity = []
          this.reportPaymentActivity['ReportPaymentActivity'] = res
          return new act.ReportPaymentActivitySuccess(this.reportPaymentActivity)
        }),
        catchError(err => {
          return from([{ type: act.REPORT_PAYMENT_ACTIVITY_UNSUCCESSFUL }])
        }),
      )
    }),
  )
  @Effect()
  getReportScoringFee: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_SCORING_FEES),
    map((action: act.ReportScoringFee) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportScoringFee(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.accountingdate = moment(v.accountingdate).format('YYYY-MM-DD')
            v.Date = moment(v.Date).format('YYYY-MM-DD hh:mm:ss A')
            v.BaseAmountCurrency = this.currencyPipe.transform(
              v.BaseAmount,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.FeeAmountCurrency = this.currencyPipe.transform(v.FeeAmount, 'USD', 'symbol', '1.2-2')
            return v
          })
          this.reportScoringFee = []
          this.reportScoringFee['ReportScoringFee'] = res
          return new act.ReportScoringFeeSuccess(this.reportScoringFee)
        }),
      )
    }),
  )
  @Effect()
  getReportProgramFee: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_PROGRAM_FEES),
    map((action: act.ReportProgramFee) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportProgramFee(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.accountingdate = moment(v.accountingdate).format('YYYY-MM-DD')
            v.Date = moment(v.Date).format('YYYY-MM-DD hh:mm:ss A')
            v.BaseAmountCurrency = this.currencyPipe.transform(
              v.BaseAmount,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.FeeAmountCurrency = this.currencyPipe.transform(v.FeeAmount, 'USD', 'symbol', '1.2-2')
            return v
          })
          this.reportProgramFee = []
          this.reportProgramFee['ReportProgramFee'] = res
          return new act.ReportProgramFeeSuccess(this.reportProgramFee)
        }),
      )
    }),
  )
  @Effect()
  getReportCWAEnableDisable: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_CWA_ENABLE_DISABLE),
    map((action: act.ReportCWAEnableDisable) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportCWAEnableDisable(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.DateModified = moment(v.DateModified).format('YYYY-MM-DD hh:mm:ss A')
            return v
          })
          this.reportCWAEnableDisable = []
          this.reportCWAEnableDisable['ReportCWAEnableDisable'] = res
          return new act.ReportCWAEnableDisableSuccess(this.reportCWAEnableDisable)
        }),
      )
    }),
  )
  @Effect()
  getReportLimitAdjustment: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_LIMIT_ADJUSTMENT),
    map((action: act.ReportLimitAdjustment) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportLimitAdjustment(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.DateModified = v.DateModified
              ? moment(v.DateModified).format('YYYY-MM-DD hh:mm:ss A')
              : ''
            return v
          })
          this.reportProgramFee = []
          this.reportProgramFee['ReportLimitAdjustment'] = res
          return new act.ReportLimitAdjustmentSuccess(this.reportProgramFee)
        }),
      )
    }),
  )
  @Effect()
  getReportFee: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_FEE),
    map((action: act.ReportFee) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportFee(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.CalendarDate = v.CalendarDate
              ? moment(v.CalendarDate).format('YYYY-MM-DD hh:mm:ss A')
              : ''
            v.GamingDate = v.GamingDate ? moment(v.GamingDate).format('YYYY-MM-DD') : ''
            v.FeeCurrency = this.currencyPipe.transform(v.Fee, 'USD', 'symbol', '1.2-2')
            return v
          })
          this.reportProgramFee = []
          this.reportProgramFee['ReportFee'] = res
          return new act.ReportFeeSuccess(this.reportProgramFee)
        }),
      )
    }),
  )
  @Effect()
  getMTSummary: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_MT_SUMMARY),
    map((action: act.ReportMTSummary) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportMTSummary(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.ModifiedDate = v.ModifiedDate
              ? moment(v.ModifiedDate).format('YYYY-MM-DD hh:mm:ss A')
              : ''
            return v
          })
          this.reportProgramFee = []
          this.reportProgramFee['MTSummary'] = res
          return new act.ReportMTSummarySuccess(this.reportProgramFee)
        }),
      )
    }),
  )
  @Effect()
  getReportUnderwriting: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_UNDERWRITING),
    map((action: act.ReportUnderwriting) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportUnderwriting(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.CREDIT_FACTOR_CREATE_DATE = v.CREDIT_FACTOR_CREATE_DATE
              ? moment(v.CREDIT_FACTOR_CREATE_DATE).format('YYYY-MM-DD hh:mm:ss A')
              : ''
            v.CREDIT_FACTOR_UPDATE_DATE = v.CREDIT_FACTOR_UPDATE_DATE
              ? moment(v.CREDIT_FACTOR_UPDATE_DATE).format('YYYY-MM-DD hh:mm:ss A')
              : ''
            v.THEO = v.THEO ? parseFloat(v.THEO).toFixed(2) : ''
            return v
          })
          this.reportProgramFee = []
          this.reportProgramFee['Underwriting'] = res
          return new act.ReportUnderwritingSuccess(this.reportProgramFee)
        }),
      )
    }),
  )
  @Effect()
  getReportCwaDetail: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_CWA_DETAIL),
    map((action: act.ReportCwaDetail) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportCwaDetail(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.GamingDate = v.GamingDate ? moment(v.GamingDate).format('YYYY-MM-DD hh:mm:ss A') : ''
            v.CalendarDateTime = v.CalendarDateTime
              ? moment(v.CalendarDateTime).format('YYYY-MM-DD hh:mm:ss A')
              : ''
            return v
          })
          this.reportCwaDetail = []
          this.reportCwaDetail['ReportCwaDetail'] = res
          return new act.ReportCwaDetailSuccess(this.reportCwaDetail)
        }),
      )
    }),
  )
  @Effect()
  getReportCwaAdjustment: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_CWA_ADJUSTMENT),
    map((action: act.ReportCwaAdjustment) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportCwaAdjustment(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.GamingDate = v.GamingDate ? moment(v.GamingDate).format('YYYY-MM-DD hh:mm:ss A') : ''
            v.CreateDate = v.CreateDate ? moment(v.CreateDate).format('YYYY-MM-DD hh:mm:ss A') : ''
            return v
          })
          this.reportCwaAdjustment = []
          this.reportCwaAdjustment['ReportCwaAdjustment'] = res
          return new act.ReportCwaAdjustmentSuccess(this.reportCwaAdjustment)
        }),
      )
    }),
  )
  @Effect()
  getPatronCwaDetails: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_PATRON_CWA_DETAILS),
    map((action: act.ReportCwaAdjustment) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getPatronCwaDetails(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.CalendarDate = v.CalendarDate ? moment(v.CalendarDate).format('YYYY-MM-DD') : ''

            return v
          })
          this.reportCwaAdjustment = []
          this.reportCwaAdjustment['ReportPatronCwaDetails'] = res
          return new act.ReportCwaAdjustmentSuccess(this.reportCwaAdjustment)
        }),
      )
    }),
  )
  @Effect()
  getReportProgManagementFees: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_PROGRAM_MANAGEMENT_FEES),
    map((action: act.ProgramManagementFees) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getProgManagementFeesReport(payload).pipe(
        map(res => {
          this.reportProgManamgementFees = []
          this.reportProgManamgementFees['ProgramManagementFees'] = res
          return new act.ProgramManagementFeesSuccess(this.reportProgManamgementFees)
        }),
      )
    }),
  )
  @Effect()
  getActivitySummary: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_ACTIVITY_SUMMARY),
    map((action: act.ActivitySummary) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getActivitySummary(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.CalendarDate = v.CalendarDate
              ? moment(v.CalendarDate).format('YYYY-MM-DD hh:mm:ss A')
              : ''
            v.GamingDate = v.GamingDate ? moment(v.GamingDate).format('YYYY-MM-DD hh:mm:ss A') : ''
            return v
          })

          this.reportProgManamgementFees = []
          this.reportProgManamgementFees['ActivitySummary'] = res
          return new act.ActivitySummarySuccess(this.reportProgManamgementFees)
        }),
      )
    }),
  )
  @Effect()
  getReportHistory: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_HISTORY),
    map((action: act.ReportHistory) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportHistory(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.DateModified = v.DateModified
              ? moment(v.DateModified).format('YYYY-MM-DD hh:mm:ss A')
              : ''
            return v
          })
          this.reportProgManamgementFees = []
          this.reportProgManamgementFees['ReportHistory'] = res
          return new act.ReportHistorySuccess(this.reportProgManamgementFees)
        }),
      )
    }),
  )
  @Effect()
  getPatronLiability: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_PATRON_LIABILITY),
    map((action: act.PatronLiability) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getPatronLiability(payload).pipe(
        map(res => {
          this.reportProgManamgementFees = []
          this.reportProgManamgementFees['PatronLiability'] = res
          return new act.PatronLiabilitySuccess(this.reportProgManamgementFees)
        }),
      )
    }),
  )
  @Effect()
  getReportGGR: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_GGR),
    map((action: act.ReportGGR) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportGGR(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.CREDIT_FACTOR_CREATE_DATE = v.CREDIT_FACTOR_CREATE_DATE
              ? moment(v.CREDIT_FACTOR_CREATE_DATE).format('YYYY-MM-DD hh:mm:ss A')
              : ''
            v.CREDIT_FACTOR_UPDATE_DATE = v.CREDIT_FACTOR_UPDATE_DATE
              ? moment(v.CREDIT_FACTOR_UPDATE_DATE).format('YYYY-MM-DD hh:mm:ss A')
              : ''
            return v
          })
          this.reportProgramFee = []
          this.reportProgramFee['GGR'] = res
          return new act.ReportGGRSuccess(this.reportProgramFee)
        }),
      )
    }),
  )
  @Effect()
  getReportPSR: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_PSR),
    map((action: act.ReportPSR) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportPSR(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.DateAccepted = v.DateAccepted
              ? moment(v.DateAccepted).format('YYYY-MM-DD hh:mm:ss A')
              : ''
            v.MarkerLimit = this.misc.transformCurrency(v.MarkerLimit)
            v.MinimumDueAmount = this.misc.transformCurrency(v.MinimumDueAmount)
            v.OutstandingBalance = this.misc.transformCurrency(v.OutstandingBalance)
            v.Surplus = this.misc.transformCurrency(v.Surplus)

            return v
          })
          this.reportPSR = []
          this.reportPSR['ReportPSR'] = res
          return new act.ReportPSRSuccess(this.reportPSR)
        }),
      )
    }),
  )
}
